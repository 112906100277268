<template>
    <ATable :url="tableUrl" :columns="columns" :searchFormItems="searchFormItems" :pagination="false" ref="table"/>
</template>


<script>
import ATable from '@/components/table/index'
import { ref } from 'vue'
import { list } from '@/api/grade'

export default {
    components: {ATable},
    setup () {
        const tableUrl = ref(list)
        const columns = ref([
            // {prop: 'id', label: 'ID'},
            {prop: 'level', label: '等级'},
            {prop: 'exp', label: '等级经验'},
            {prop: 'financialCount', label: '等级需要的财商币'},
        ])
        const searchFormItems = ref([
            // {label: '标题', prop: 'title', type: 'input'},
        ])

        return {
            tableUrl,
            columns,
            searchFormItems
        }
    }
}
</script>
